.f69cTractor1Icon {
  position: relative;
  width: 48px;
  height: 48px;
  object-fit: cover;
}
.swiftuiColorIn {
  position: relative;
  line-height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 291px;
}
.lastUpdate120226 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 18px;
  font-weight: 500;
  color: var(--dark-content-4);
  text-align: left;
}
.f69cTractor1Parent {
  position: absolute;
  top: 100px;
  left: 0;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--padding-base) var(--padding-5xl);
  box-sizing: border-box;
  gap: var(--gap-5xl);
  text-align: center;
  font-size: var(--font-size-13xl);
}
.notchIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 30px;
}
.networkSignalLight {
  position: relative;
  width: 20px;
  height: 14px;
}
.batteryLight,
.wifiSignalLight {
  position: relative;
  width: 16px;
  height: 14px;
}
.batteryLight {
  width: 25px;
}
.statusIcons {
  position: absolute;
  top: 16px;
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.indicatorIcon {
  position: absolute;
  top: 8px;
  right: 71px;
  width: 6px;
  height: 6px;
}
.timeLight {
  position: absolute;
  top: 12px;
  left: 21px;
  border-radius: var(--br-xl);
  width: 54px;
  height: 21px;
  overflow: hidden;
}
.star242Icon,
.systemStatus {
  position: relative;
  width: 375px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.star242Icon {
  width: 24px;
  height: 24px;
}
.star242Parent,
.star243Parent {
  display: flex;
  flex-direction: row;
}
.star243Parent {
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.star242Parent {
  width: 375px;
  height: 56px;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
}
.systemStatusParent {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.star54Icon {
  position: relative;
  width: 25px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.askMeAnything {
  position: relative;
  line-height: 22px;
}
.component,
.component4,
.star54Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.component {
  flex: 1;
  overflow: hidden;
}
.component4,
.star54Parent {
  box-sizing: border-box;
}
.component4 {
  flex: 1;
  border-radius: var(--br-18xl);
  background-color: var(--dark-gray-01);
  border-bottom: 1px solid var(--dark-alpha-09);
  height: 40px;
  padding: var(--padding-xs);
}
.star54Parent {
  width: 375px;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-base);
}
.homeIndicator {
  position: absolute;
  height: 17.65%;
  width: 35.47%;
  top: 58.82%;
  right: 32.27%;
  bottom: 23.53%;
  left: 32.27%;
  border-radius: var(--br-10xs);
  background-color: var(--dark-content-1);
}
.systemFooter {
  position: relative;
  width: 375px;
  height: 34px;
}
.frameParent {
  position: absolute;
  top: 723px;
  left: 0;
  background-color: var(--dark-alpha-11);
  backdrop-filter: var(--blur);
  border: 1px solid var(--dark-alpha-10);
  box-sizing: border-box;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: var(--dark-content-6);
}
.span {
  color: var(--color-mediumseagreen-100);
}
.app {
  color: var(--color-cornflowerblue);
}
.span1 {
  color: var(--dark-content-4);
}
.route {
  color: var(--color-mediumorchid);
}
.approutePingMethods {
  margin: 0;
}
.return {
  color: #ffcb47;
}
.blankLine {
  margin: 0;
  color: var(--dark-content-4);
}
.span16 {
  color: #ec5d41;
}
.approutePingContainer1 {
  line-break: anywhere;
  width: 100%;
}
.approutePingContainer {
  position: relative;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 291px;
  flex-shrink: 0;
}
.approutePingMethodsWrapper {
  position: absolute;
  top: 418px;
  left: 16px;
  border-radius: var(--br-3xs);
  background-color: var(--dark-alpha-12);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: var(--padding-5xs);
  color: var(--color-cornflowerblue);
}
.whichMovieAreContainer {
  position: relative;
  line-height: 22px;
  font-weight: 500;
}
.whichMovieAreYouMostExcitWrapper {
  position: absolute;
  top: 346px;
  right: 16px;
  border-radius: var(--br-3xs);
  background-color: var(--dark-indigo-09);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: var(--padding-5xs);
  color: var(--dark-content-1);
}
.groupChild,
.iphone13Mini29 {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  height: 812px;
}
.iphone13Mini29 {
  background: radial-gradient(50% 50%at 50% 50%, #000, #16305c);
  overflow: hidden;
}
.groupChild {
  /* background-color: rgba(0, 0, 0, 0.6); */
  backdrop-filter: blur(80px);
}
.siriIcon,
.systemStatus1 {
  position: absolute;
  overflow: hidden;
}
.systemStatus1 {
  top: 0;
  left: 0;
  width: 375px;
  height: 44px;
}
.siriIcon {
  height: calc(100% - 635.26px);
  width: calc(100% - 206.18px);
  top: 542.75px;
  right: 99.24px;
  bottom: 92.51px;
  left: 106.94px;
  max-width: 100%;
  max-height: 100%;
}
.whichRestaurantsGenerated {
  position: absolute;
  top: 237px;
  left: calc(50% - 149.5px);
  line-height: 38px;
  display: flex;
  align-items: center;
  width: 299px;
  color: #fff;
}
.instanceParent {
  position: absolute;
  top: 722px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.rectangleParent {
  position: absolute;
  top: 0;
  left: 0;
  width: 375px;
  height: 812px;
  font-size: var(--font-size-13xl);
  color: var(--dark-content-2);
}
.voiceQuery {
  position: relative;
  width: 100%;
  height: 812px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--light-content-1);
  font-family: var(--font-montserrat);
}
