/* src/styles/suggestedQuestions.module.css */
.suggestedQuestionsContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin: 10px 0px;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 15px;
  }
  
  .suggestionBox {
    background-color: #17112f;
    padding: 5px 10px;
    border-radius: 10px;
    cursor: pointer;
    transition: background-color 0.5s;
    width: 30%;
    border: 1px solid #987f1d;
    font-size: 12px;
    color: #fff;
    max-height: 80px;
    display: flex;
    align-items: center;
    overflow: scroll;
    margin-right: 15px;
  }
  
  .suggestionBox:hover {
    background-color: transparent;
    color: #ffffff;
  }
  .suggestedQuestionsDiv > div:nth-child(3) {
    margin-right: 0px;
  }
  