.f69cTractor1Icon {
  position: relative;
  border-radius: var(--br-31xl);
  width: 24px;
  height: 24px;
  object-fit: cover;
}
.thatsATough {
  position: relative;
  line-height: 18px;
  font-weight: 500;
  color: #fff;
}
.f69cTractor1Parent {
  /* position: absolute; */
  top: 142px;
  right: 17px;
  border-radius: var(--br-xs);
  background-color: var(--cw-purple);
  box-shadow: var(--shadow);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  gap: var(--gap-5xs);
  text-align: left;
  color: var(--dark-content-1);
  margin-top: 9em;
  width: 70%;
  float: right;
}
.homeFill2Icon {
  position: relative;
  width: 24px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
}
.homeFill2Wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  opacity: 0.3;
}
.bookmark52Wrapper,
.homeFill2Wrapper {
  flex: 1;
  display: flex;
  padding: var(--padding-base) var(--padding-3xs);
}
.bookmark52Wrapper,
.frameGroup,
.star53Wrapper {
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.star53Wrapper {
  flex: 1;
  display: none;
  padding: var(--padding-base) var(--padding-3xs);
  opacity: 0.3;
}
.frameGroup {
  align-self: stretch;
  display: flex;
  padding: 0 var(--padding-5xl);
}
.homeIndicator {
  position: absolute;
  height: 17.65%;
  width: 35.47%;
  top: 58.82%;
  right: 32.27%;
  bottom: 23.53%;
  left: 32.27%;
  border-radius: var(--br-10xs);
  background-color: var(--dark-content-1);
}
.systemFooter {
  position: relative;
  width: 375px;
  height: 34px;
}
.frameParent {
  position: absolute;
  top: 722px;
  left: 0;
  background-color: var(--dark-alpha-11);
  backdrop-filter: var(--blur);
  border-top: 1px solid var(--dark-alpha-10);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.notchIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 30px;
}
.networkSignalLight {
  position: relative;
  width: 20px;
  height: 14px;
}
.batteryLight,
.wifiSignalLight {
  position: relative;
  width: 16px;
  height: 14px;
}
.batteryLight {
  width: 25px;
}
.statusIcons {
  position: absolute;
  top: 16px;
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.indicatorIcon {
  position: absolute;
  top: 8px;
  right: 71px;
  width: 6px;
  height: 6px;
}
.timeLight {
  position: absolute;
  top: 12px;
  left: 21px;
  border-radius: var(--br-xl);
  width: 54px;
  height: 21px;
  overflow: hidden;
}
.systemStatus {
  position: relative;
  width: 375px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.systemStatusParent {
  position: absolute;
  top: 1px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.revenue {
  /* position: absolute; */
  top: 53px;
  left: 55px;
  font-size: 32px;
  line-height: 38px;
  color: var(--dark-content-1);
  text-align: left;
  padding-left: 10px;
}
.star242Parent {
  position: fixed;
  /* top: 44px; */
  left: 0;
  width: 100%;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
  height: 73px;
  z-index: 9999999;
  background-color: #00000099;
}
.image28Icon {
  position: absolute;
  top: 325px;
  left: 33px;
  width: 200px;
  height: 200px;
  object-fit: cover;
}
.component7frame491Child {
  position: relative;
  border-radius: 50%;
  background-color: var(--light-red-09);
  width: 8px;
  height: 8px;
}
.component7frame491 {
  position: absolute;
  top: 356px;
  left: 249px;
  border-radius: var(--br-21xl);
  background-color: var(--dark-alpha-12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-3xs);
}
.component7frame492Child {
  position: relative;
  border-radius: 50%;
  background-color: var(--light-blue-08);
  width: 8px;
  height: 8px;
}
.component7frame492 {
  position: absolute;
  top: 404px;
  left: 249px;
  border-radius: var(--br-21xl);
  background-color: var(--dark-alpha-12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-3xs);
}
.component7frame493Child {
  position: relative;
  border-radius: 50%;
  background-color: var(--light-yellow-09);
  width: 8px;
  height: 8px;
}
.component7frame493 {
  position: absolute;
  top: 452px;
  left: 249px;
  border-radius: var(--br-21xl);
  background-color: var(--dark-alpha-12);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-3xs);
}
.f69cTractor1Icon1 {
  position: relative;
  border-radius: var(--br-31xl);
  width: 24px;
  height: 24px;
  object-fit: cover;
  display: none;
}
.thatsATough1 {
  flex: 1;
  position: relative;
  line-height: 22px;
  color: #fff;
}
.f69cTractor1Group {
  /* position: absolute; */
  top: 205px;
  left: 28px;
  border-radius: var(--br-xs);
  background-color: var(--dark-alpha-12);
  box-shadow: var(--shadow);
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-xs);
  box-sizing: border-box;
  gap: var(--gap-5xs);
  text-align: left;
  margin-bottom: 30px;
}
.component7frame494Child {
  position: relative;
  border-radius: 50%;
  background-color: var(--cw-purple);
  width: 8px;
  height: 8px;
}
.component7frame494 {
  /* position: absolute; */
  top: 570px;
  left: 33px;
  border-radius: 40px;
  background-color: var(--dark-alpha-12);
  border: 1px solid var(--cw-purple);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: var(--padding-xs) var(--padding-base);
  gap: var(--gap-3xs);
}
.response {
  position: relative;
  background: radial-gradient(50% 50%at 50% 50%, #000, #16305c);
  width: 100%;
  height: 812px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-sm);
  color: var(--dark-content-2);
  font-family: var(--font-montserrat);
}
.piechartMainDiv{
    position: absolute;
    top: 200px;
}
.areachartMainDiv{
  position: absolute;
  top: 300px;
}
.response_screen{
  height: 715px!important;
  overflow: auto;
}
@media screen and (min-width:600px)  {
  .response_screen{
    height: auto!important;
  }
  .response{
    height: 1183px;
  }
  .systemStatusParent{
    align-items: flex-start;
  }
  .frameGroup{
    width: 100%!important;
  }
}
