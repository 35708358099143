.chatContainerWrapper{
  padding: 20px;
}
.chatWrapper{
  padding: 10px 10px 0px;
  height: 75vh;
  overflow-y: scroll;
  margin-bottom: 10px;
}
.startADialog {
  position: relative;
  line-height: 38px;
}
.lastUpdate120226 {
  position: relative;
  font-size: var(--font-size-sm);
  line-height: 18px;
  font-weight: 500;
  color: var(--dark-content-4);
  text-align: left;
}
.startADialogParent {
  position: absolute;
  top: 100px;
  left: 0;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: var(--padding-base) 40px;
  box-sizing: border-box;
  gap: 9px;
  text-align: center;
  font-size: 32px;
}
.notchIcon {
  position: absolute;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  height: 30px;
}
.networkSignalLight {
  position: relative;
  width: 20px;
  height: 14px;
}
.batteryLight,
.wifiSignalLight {
  position: relative;
  width: 16px;
  height: 14px;
}
.batteryLight {
  width: 25px;
}
.statusIcons {
  position: absolute;
  top: 16px;
  right: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-9xs);
}
.indicatorIcon {
  position: absolute;
  top: 8px;
  right: 71px;
  width: 6px;
  height: 6px;
}
.timeLight {
  position: absolute;
  top: 12px;
  left: 21px;
  border-radius: var(--br-xl);
  width: 54px;
  height: 21px;
  overflow: hidden;
}
.star242Icon,
.systemStatus {
  position: relative;
  width: 375px;
  height: 44px;
  overflow: hidden;
  flex-shrink: 0;
}
.star242Icon {
  width: 24px;
  height: 24px;
}
.star242Parent {
  width: 375px;
  height: 56px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: var(--padding-5xs) var(--padding-base);
  box-sizing: border-box;
}
.systemStatusParent {
  /* position: absolute; */
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.brainstormIdeas,
.edit51Icon {
  position: relative;
  flex-shrink: 0;
}
.edit51Icon {
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.brainstormIdeas {
  line-height: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  width: 100%;
}
.edit51Parent {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-5xs);
}
.arrowLeft52Icon {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
  flex-shrink: 0;
  opacity: 0.3;
}
.frameParent {
  align-self: stretch;
  border-bottom: 1px solid var(--dark-gray-04);
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs) 0;
  gap: var(--gap-3xs);
}
.frameParent,
.frameParent1,
.instanceParent {
  display: flex;
  justify-content: flex-start;
}
.frameParent1 {
  align-self: stretch;
  flex-direction: row;
  align-items: center;
  padding: var(--padding-xs) 0;
  gap: var(--gap-5xs);
}
.instanceParent {
  position: absolute;
  top: 243px;
  left: 16px;
  border-radius: 10px;
  background-color: var(--dark-alpha-12);
  box-shadow: var(--shadow);
  /* width: 343px; */
  overflow: hidden;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 var(--padding-xs);
  box-sizing: border-box;
  color: var(--dark-content-2);
}
.image51Icon,
.star54Icon {
  position: relative;
  width: 25px;
  height: 24px;
  overflow: hidden;
  flex-shrink: 0;
  display: none;
}
.image51Icon {
  width: 24px;
}
.askMeAnything {
  position: relative;
  line-height: 22px;
}
.component,
.component4,
.star54Parent {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}
.component {
  flex: 1;
  overflow: hidden;
}
.component4,
.star54Parent {
  box-sizing: border-box;
}
.component4 {
  flex: 1;
  border-radius: var(--br-18xl);
  background-color: var(--dark-alpha-gray-03);
  height: 40px;
  padding: var(--padding-xs);
}
.star54Parent {
  width: 375px;
  padding: var(--padding-5xs) var(--padding-base);
  gap: var(--gap-base);
}
.homeIndicator {
  position: absolute;
  height: 17.65%;
  width: 35.47%;
  top: 58.82%;
  right: 32.27%;
  bottom: 23.53%;
  left: 32.27%;
  border-radius: var(--br-10xs);
  background-color: var(--dark-content-1);
}
.systemFooter {
  position: relative;
  width: 375px;
  height: 34px;
  display: none;
}
.frameParent2 {
  background-color: var(--dark-alpha-11);
  backdrop-filter: var(--blur);
  border: 1px solid var(--dark-alpha-10);
  box-sizing: border-box;
  width: 375px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}
.letter {
  position: absolute;
  width: 100%;
  top: calc(50% - 15.5px);
  left: 0;
  text-transform: lowercase;
  font-weight: 300;
  display: inline-block;
}
.keysLetter,
.keysLetter1,
.keysLetter2,
.keysLetter3,
.keysLetter4,
.keysLetter5,
.keysLetter6 {
  position: absolute;
  width: calc(100% - 228px);
  top: 0;
  right: 228px;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  height: 43px;
}
.keysLetter1,
.keysLetter2,
.keysLetter3,
.keysLetter4,
.keysLetter5,
.keysLetter6 {
  right: 190px;
  left: 38px;
}
.keysLetter2,
.keysLetter3,
.keysLetter4,
.keysLetter5,
.keysLetter6 {
  right: 152px;
  left: 76px;
}
.keysLetter3,
.keysLetter4,
.keysLetter5,
.keysLetter6 {
  right: 114px;
  left: 114px;
}
.keysLetter4,
.keysLetter5,
.keysLetter6 {
  right: 76px;
  left: 152px;
}
.keysLetter5,
.keysLetter6 {
  right: 38px;
  left: 190px;
}
.keysLetter6 {
  right: 0;
  left: 228px;
}
.bottomRow {
  width: calc(100% - 115px);
  top: 116px;
  right: 57px;
  left: 58px;
}
.bottomRow,
.keysLetter7,
.keysLetter8 {
  position: absolute;
  height: 43px;
}
.keysLetter7 {
  width: calc(100% - 304px);
  top: 0;
  right: 304px;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter8 {
  right: 266px;
  left: 38px;
}
.keysLetter10,
.keysLetter8,
.keysLetter9 {
  width: calc(100% - 304px);
  top: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter9 {
  position: absolute;
  right: 228px;
  left: 76px;
  height: 43px;
}
.keysLetter10 {
  right: 190px;
  left: 114px;
}
.keysLetter10,
.keysLetter11,
.keysLetter12 {
  position: absolute;
  height: 43px;
}
.keysLetter11 {
  width: calc(100% - 304px);
  top: 0;
  right: 152px;
  left: 152px;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter12 {
  right: 114px;
  left: 190px;
}
.keysLetter12,
.keysLetter13,
.keysLetter14 {
  width: calc(100% - 304px);
  top: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter13 {
  position: absolute;
  right: 76px;
  left: 228px;
  height: 43px;
}
.keysLetter14 {
  right: 38px;
  left: 266px;
}
.keysLetter14,
.keysLetter15,
.middleRow {
  position: absolute;
  height: 43px;
}
.keysLetter15 {
  width: calc(100% - 304px);
  top: 0;
  right: 0;
  left: 304px;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.middleRow {
  width: calc(100% - 39px);
  top: 62px;
  right: 17.25px;
  left: 21.75px;
}
.keysLetter16 {
  width: calc(100% - 333px);
  top: 0;
  right: 333px;
  left: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter16,
.keysLetter17,
.keysLetter18 {
  position: absolute;
  height: 43px;
}
.keysLetter17 {
  width: calc(100% - 333px);
  top: 0;
  right: 296px;
  left: 37px;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter18 {
  right: 259px;
  left: 74px;
}
.keysLetter18,
.keysLetter19,
.keysLetter20 {
  width: calc(100% - 333px);
  top: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter19 {
  position: absolute;
  right: 222px;
  left: 111px;
  height: 43px;
}
.keysLetter20 {
  right: 185px;
  left: 148px;
}
.keysLetter20,
.keysLetter21,
.keysLetter22 {
  position: absolute;
  height: 43px;
}
.keysLetter21 {
  width: calc(100% - 333px);
  top: 0;
  right: 148px;
  left: 185px;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter22 {
  right: 111px;
  left: 222px;
}
.keysLetter22,
.keysLetter23,
.keysLetter24 {
  width: calc(100% - 333px);
  top: 0;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.keysLetter23 {
  position: absolute;
  right: 74px;
  left: 259px;
  height: 43px;
}
.keysLetter24 {
  right: 37px;
  left: 296px;
}
.keysLetter24,
.keysLetter25,
.topRow {
  position: absolute;
  height: 43px;
}
.keysLetter25 {
  width: calc(100% - 333px);
  top: 0;
  right: 0;
  left: 333px;
  border-radius: var(--br-8xs);
  background-color: var(--dark-primary);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
}
.topRow {
  width: calc(100% - 11px);
  top: 8px;
  right: 6px;
  left: 5px;
}
.keysIcon,
.keysIcon1 {
  position: absolute;
  top: 116px;
  border-radius: var(--br-8xs);
  width: 42px;
}
.keysIcon {
  left: 3px;
  height: 44px;
}
.keysIcon1 {
  right: 3px;
  height: 43px;
}
.themedarkOrientationportra {
  align-self: stretch;
  position: relative;
  backdrop-filter: blur(70px);
  height: 169px;
  overflow: hidden;
  flex-shrink: 0;
}
.homeIndicator1 {
  position: absolute;
  top: 110px;
  left: calc(50% - 66.5px);
  border-radius: var(--br-10xs);
  background-color: var(--dark-content-1);
  width: 133px;
  height: 6px;
}
.emojisIcon {
  position: absolute;
  top: 64px;
  left: 18px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.letter26 {
  position: absolute;
  width: 100%;
  top: calc(50% - 9.5px);
  left: 0;
  letter-spacing: -0.02em;
  display: inline-block;
}
.keysLabel,
.keysLabel1,
.keysLabel2 {
  position: absolute;
  top: 3px;
  border-radius: var(--br-8xs);
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.3);
  height: 43px;
}
.keysLabel {
  width: calc(100% - 193px);
  right: 97px;
  left: 96px;
  background-color: var(--dark-primary);
}
.keysLabel1,
.keysLabel2 {
  background-color: var(--color-darkslategray);
  width: 87px;
}
.keysLabel1 {
  right: 3px;
}
.keysLabel2 {
  left: 3px;
}
.micIcon {
  position: absolute;
  top: 64px;
  right: 18px;
  width: 42px;
  height: 42px;
  overflow: hidden;
}
.layoutdefaultWithMicTheme {
  align-self: stretch;
  position: relative;
  height: 124px;
  overflow: hidden;
  flex-shrink: 0;
  font-size: var(--font-size-base);
}
.instanceGroup,
.systemKeyboards {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.systemKeyboards {
  background: linear-gradient(rgba(0, 0, 0, 0.82), rgba(0, 0, 0, 0.82)),
    rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(70px);
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-5xl);
  color: var(--dark-content-1);
}
.instanceGroup {
  position: absolute;
  top: 463px;
  left: 0;
  color: var(--dark-content-6);
}
.newDialog {
  position: relative;
  /* background: radial-gradient(50% 50%at 50% 50%, #000, #16305c); */
  background: #000;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--dark-content-1);
  font-family: var(--font-montserrat);
}
.chatMessageInputWrapper{
  /* border-radius: 40px; */
  background: transparent;
  color: #fff;
  border: 0;
  height: 45px;
  padding: 0px 14px;
  outline: none;
  width: 100%;
  font-size: 16px;
}

.inputWrapper{
    border-radius: 40px;
    background: transparent;
    color: #fff;
    border: 1px solid #7357EB;
    height: 45px;
    padding: 0px 14px;
    width: 80%;
}
.inputWrapperDiv{
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: 119px;
  width: 100%;

}
.settings_icon{
  position: fixed;
  right: 30px;
  top: 46px;
   width:35%;
   text-align: right;
}

@media screen and (min-width:600px)  {
  
  .newDialog{
    /* height: 1183px; */
  }
  .home{
    height: 1183px;
  }
  .systemStatusParent{
    align-items: flex-start;
  }
  .frameGroup{
    width: 100%!important;
  }
}
@media (max-width: 600px) {
  .newDialog{
    height: 100vh;
  }
  .chatWrapper{
    height: 80vh;
    padding: 10px 0px;
  }
  .chatContainerWrapper{
    padding: 0px 0px;
  }
  .chatWrapper{
    margin-bottom: 0px;
  }
  .chatMessageInputWrapper{
    margin-left: 10px;
  }
}